import { animate as animate$1, wrapAnimationWithControls } from '@motionone/dom';
import { Animation } from '@motionone/animation';

function animateProgress(target, options) {
    return wrapAnimationWithControls([
        () => {
            const animation = new Animation(target, [0, 1], options);
            animation.finished.catch(() => { });
            return animation;
        },
    ], options === null || options === void 0 ? void 0 : options.duration);
}
function animate(target, keyframesOrOptions, options) {
    const animationFunction = typeof target === "function" ? animateProgress : animate$1;
    return animationFunction(target, keyframesOrOptions, options);
}

export { animate, animateProgress };
